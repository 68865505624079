@font-face {
    font-family: 'Amplitude';
    src: url(/api/fonts/amplitude-regular-webfont.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}

body {
    background-color: black !important;
    overflow-x: hidden !important;
    overflow-y: scroll !important;
    padding: 0 !important;
    margin: 0;
}

.navbar-brand>img {
    height: 50px;
    width: auto;
}

.login-form {
    margin: 0 auto;
    margin-top: 10vh;
    color: white;
}

.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2;
    border-radius: 2;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
}

.blurred-loading-image {
    filter: blur(20px);
}

.overlay {
    transition: 0.5s;
    color: black;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    opacity: 0;
}

@media (max-width: 700px) {
    .overlay p {
        font-size: 1.5rem;
        max-width: 90% !important;
    }
    .overlay img {
        width: 40px !important;
    }
}

.overlay p {
    font-family: 'Amplitude', sans-serif;
    font-size: 2rem;
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    max-width: 95%;
    margin: 0 auto;
}

.overlay img {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    bottom: 5px;
    text-align: center;
    width: 100px;
    margin: 0 auto;
}

.dropdown-item:hover {
    color: black !important;
}

.overlay:hover {
    color: white;
    background-color: black;
    opacity: 0.5;
}

.image-overlay-container {
    position: relative;
}

.normal-image {
    filter: blur(0px);
}

.image-fade {
    transition: all .5s ease;
    transition-delay: 0.5s;
}

.hide-img-overflow {
    overflow: hidden;
}

.card {
    text-align: center;
    min-height: 300px;
}

.dropzone-container {
    width: 75vw;
}

.addVaultCards {
    padding: 10px;
}

.addVaultCards>span {
    margin: auto auto;
}

@media (max-width: 517px) {
    .arrow_b9bbag-o_O-arrow__direction__right_174p6a9-o_O-arrow__size__medium_9f7hgo svg {
        fill: black !important;
    }
    .arrow_b9bbag-o_O-arrow__direction__left_shhpn5-o_O-arrow__size__medium_9f7hgo svg {
        fill: black !important;
    }
    .logo-block>img {
        width: 15rem !important;
    }
}

@media (max-width: 576px) {
    .logo-block>img {
        width: 20rem;
    }
    .dot {
        display: block !important;
        visibility: hidden;
    }
    .logo-block {
        width: 100vw !important;
    }
}

@media (max-width: 353px) {}

@media (max-width: 560px) {
    #ReactGridGallery {
        margin: 0 auto;
        width: 81%;
    }
}

#ReactGridGallery {
    margin: 0 auto;
    width: 100%;
    max-width: 1500px;
}

@media (max-width: 1100px) {
    .navbar .container, .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
        display: block !important;
        text-align: center;
    }
}

.logo-block-text {
    margin-top: -0.5rem;
}

#lightboxBackdrop {
    -webkit-animation: fadein 1s;
    /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 1s;
    /* Firefox < 16 */
    -ms-animation: fadein 1s;
    /* Internet Explorer */
    -o-animation: fadein 1s;
    /* Opera < 12.1 */
    animation: fadein 1s;
}

.table th {
    border-top: none !important;
    color: white;
}

.lazy-load-image-background.blur {
    filter: blur(15px);
}

.lazy-load-image-background.blur.lazy-load-image-loaded {
    filter: blur(0);
    transition: filter .3s;
}

.hamburger-icon {
    position: -webkit-sticky;
    position: fixed;
    top: 8px;
    z-index: 10000;
    left: 8px;
    transform: translate3d(0, 0, 0);
}

.hamburger-icon {
    outline: none !important;
}

.slick-slide, .slick-slide * {
    outline: none !important;
}

.hamburger-offset {
    position: fixed;
    z-index: 10000;
    width: 100%;
    transform: translate3d(0, 0, 0);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.521), transparent)
}

.main-nav-link, .main-nav-link a {
    color: white;
}

.main-nav-link>.dropdown-menu {
    background-color: black;
}

.carousel {
    height: 75vh;
    overflow-y: hidden;
}

.carousel-container {
    width: 100%;
    margin: 0 auto;
}

.carousel-image {
    width: 100%;
    height: 75vh;
    margin: 0 auto;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.slick-slider>.slick-prev {
    left: 10px;
    z-index: 1;
}

.slick-slider>.slick-next {
    right: 10px;
    z-index: 1;
}

.selected-clients .container {
    z-index: 1000;
    background-color: black;
}

.selected-clients>div>div>div>img {
    width: 100%;
}

.selected-clients p {
    color: white;
}

.selected-clients-p1 {
    font-family: 'Roboto';
    letter-spacing: 2px;
    font-weight: lighter;
    font-size: 0.8rem;
    margin: 0;
}

.selected-clients-p2 {
    margin: 0;
    margin-top: -5px;
    font-family: 'Roboto';
    font-size: 1.8rem;
}

.vertical-center {
    display: flex;
    align-items: center;
}

.logo-block {
    color: white;
    font-family: 'Amplitude', sans-serif;
    position: absolute;
    width: 550px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

.logo-block>img {
    width: 25rem;
}

.logo-block>p {
    margin: 0;
    padding: 5px;
}

.horiz-divider {
    margin: 15px auto;
    height: 1px;
    width: 100px;
    background-color: white;
}

.char-spacing-2 {
    letter-spacing: 2px;
}

.banner-text {
    font-size: 1.3rem;
    margin: 0;
}

.dot {
    background: white;
    border-radius: 50%;
    display: inline-block;
    height: 0.6rem;
    margin-left: 18px;
    margin-bottom: 1px;
    margin-right: 18px;
    width: 0.6rem;
}

.selected-work {
    background-color: #343434;
}

.selected-work>.container-fluid {
    color: white;
    max-width: 1900px;
    margin: 0 auto;
}

.my-gallery-class {
    list-style-type: none;
}

.image-element-class {
    padding: 5px;
    width: auto;
}

.footer {
    background-color: black;
    color: white;
    min-height: 25vh;
}

.footer p {
    font-family: 'Amplitude', sans-serif;
}

.footer ul {
    list-style-type: none;
}

.footer li {
    font-family: 'Roboto', sans-serif;
}

.middle-footer {
    border-left: 1px solid grey;
}

.relative-position {
    position: relative;
}

.center-row {
    position: absolute;
    /* 2 */
    top: 50%;
    /* 3 */
    transform: translate(0, -50%)/* 4 */
}

.footer img {
    max-width: 300px;
}

@media screen and (max-width: 994px) {
    .image-element-class>img {
        width: 25vw;
        margin: auto;
    }
}

.table-responsive td {
    color: white;
}

.page {
    color: white;
}

.navContainer a:link {
    color: white;
}

.navContainer a:active {
    color: white;
}

.navContainer a {
    color: white;
}

.navContainer a:hover {
    color: white;
}

.BeatLoader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #AE201F;
}

.middle-footer>ul>a {
    color: white;
}

.visibility-hidden {
    height: 0;
    overflow-y: hidden;
}

.fade-in {
    -webkit-animation: fadein 1s;
    /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 1s;
    /* Firefox < 16 */
    -ms-animation: fadein 1s;
    /* Internet Explorer */
    -o-animation: fadein 1s;
    /* Opera < 12.1 */
    animation: fadein 1s;
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Firefox < 16 */

@-moz-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Safari, Chrome and Opera > 12.1 */

@-webkit-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Internet Explorer */

@-ms-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Opera < 12.1 */

@-o-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

h2 {
    color: white;
}

.vault-table tbody tr td {
    color: black !important;
}

.navbar-shadow {
    top: 0;
    position: fixed;
    height: 70px;
    opacity: 0.4;
    background: linear-gradient(to bottom, #070000b2, transparent);
    width: 100%;
    z-index: 2000;
}

.top-margin {
    margin-top: 70px;
}

.contact-form {
    color: white;
}

.selected-work {
    min-height: 52vh;
}

.slick-slide {
    background-color: #AE201F;
}

.slick-slide, .slick-slider .slick-track, .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-perspective: 1000;
    -moz-perspective: 1000;
    -ms-perspective: 1000;
    perspective: 1000;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
}

.slick-slider.slick-initialized, .slick-list, .slick-track, .slick-slide.slick-cloned {
    height: 100%;
}

.slick-slide * {
    min-height: 100%;
    min-width: 100%;
}

.slider-image, .each-slide {
    min-width: 100vw;
    min-height: 100%;
}

img {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    -webkit-user-select: none;
    -webkit-user-drag: none;
    -webkit-app-region: no-drag;
    cursor: default;
}

.react-images__view {
    max-width: 1000px;
    margin: 0 auto;
}

.gallery-container {
    max-width: 1500px;
    margin: 0 auto;
}

.react-images__positioner {
    transition: none !important;
}

#close1 {
    width: 25px;
    height: 25px;
    opacity: 0.6;
    z-index: 2001;
    position: relative;
    float: right;
}

.close2 {
    height: 25px;
    width: 2px;
    margin-left: 12px;
    background-color: white;
    transform: rotate(45deg);
    Z-index: 1;
}

.close3 {
    height: 25px;
    width: 2px;
    background-color: white;
    transform: rotate(90deg);
    Z-index: 2;
}

@media screen and (min-width: 1050px){
    .header-container{
        width: 1050px;
        height: 25px;
        margin-bottom: -25px !important;
        margin: 0 auto;
    }
}
.modal-image{
    width: 100%;
}
.modal-content{
    background: none !important;
    border: none;
}
.contact-form{
    font-family: 'Roboto';
}
.contact-form input{
    border-radius: 0;
}
.contact-form textarea{
    border-radius: 0;
}
.contact-form button{
    background-color: #AE201F;
    border: none;
}
.grecaptcha-badge { 
    visibility: hidden;
}